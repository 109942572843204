import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ fontFamily: 'Courier New, Courier, monospace' }}> Welcome to Bernard Labs. </p>
        <p style={{ fontSize: '12px', color: '#61DAFB', fontFamily: 'Courier New, Courier, monospace' }}>
          We build creative, elegant software solutions for systematic and quantitative investment strategies.
        </p>      
      </header>
    </div>
  );
}

export default App;
